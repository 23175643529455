<template>
  <section class="mini-page__intro">
    <!-- Price -->
    <MiniPrice
      :language="language"
      :cur-data="curData"
      :cur-price="curPrice"
      :show-star-rating="config.showStarRating"
      :shein-club-info="sheinClubInfo"
      :pretreat-info="pretreatInfo"
      :constant-data="constantData"
      :retention-config="retentionConfig"
      :config="config"
    />

    <!-- Title -->
    <MiniGoodsName
      :language="language"
      :cur-data="curData"
    />

    <!-- Tags -->
    <MiniTags
      :pretreat-info="pretreatInfo"
      :cur-data="curData"
      :config="config"
    />
  </section>
</template>

<script>
import MiniPrice from '../MiniPrice'
import MiniGoodsName from './MiniGoodsName'
import MiniTags from './MiniTags'
export default {
  name: 'MiniPageIntro',
  components: {
    MiniPrice,
    MiniTags,
    MiniGoodsName
  },
  props: {
    curData: {
      type: Object,
      default: () => {}
    },
    language: {
      type: Object,
      default: () => {}
    },
    pretreatInfo: {
      type: Object,
      default: () => {}
    },
    constantData: {
      type: Object,
      default: () => {}
    },
    curPrice: {
      type: Object,
      default: () => {}
    },
    sheinClubInfo: {
      type: Object,
      default: () => {}
    },
    config: {
      type: Object,
      default: () => {}
    },
    retentionConfig: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="less">
.mini-page__intro {
  padding: 0 16/75rem;
}
</style>
