<template>
  <div
    class="mini-btn__container"
    :class="{ 'loading': loading }"
  >
    <!-- 收藏 -->
    <BtnSave
      v-model="wishStatus"
      role="button"
      :aria-label="wishStatus ? language.SHEIN_KEY_PWA_15007 : language.SHEIN_KEY_PWA_15006"
      :goods_id="curData.goods_id"
      :goods_sn="curData.goods_sn"
      :index="index"
      :detail="curData"
      :config="wishAnalysis"
      :board-toast-pos-type="3"
      :sku-info="skuInfo"
      :mall-code="curData.mall_code"
      @toggleWishStatus="handleToggleWishStatus"
    />
    <!-- BTN -->
    <button
      class="mshe-btn-black add-btn"
      :class="{ 'disabled': isSoldOut }"
      role="text"
      :aria-label="language.SHEIN_KEY_PWA_15019"
      @click="clickAddHandle"
    >
      {{ language.SHEIN_KEY_PWA_15019 }}
    </button>
  </div>
</template>

<script>
import BtnSave from 'public/src/pages/components/product/btn_save.vue'
import { getImageRatio } from 'public/src/pages/goods_detail/utils/common.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { debounce } from '@shein/common-function'

export default {
  name: 'MiniAddBtn',
  components: { BtnSave },
  props: {
    language: {
      type: Object,
      default: () => {}
    },
    curData: {
      type: Object,
      default: () => {}
    },
    config: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: 0
    },
    isSoldOut: {
      type: Boolean,
      default: false
    },
    externalSizeInfoIndex: {
      type: Object,
      default: () => {}
    },
    skuInfo: {
      type: Object,
      default: () => {}
    },
    wishStatus: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: true,
      quickAddInstance: null,
      // isOnAddToBagSuccess: false
    }
  },
  computed: {
    wishAnalysis() {
      return {
        sourceTarget: this.$parent.$el,
        sa: {
          activity_from: this.config?.sa?.activity_from ?? 'goods_list'
        }
      }
    }
  },
  created() {
    this.quickAddInstance = null
  },
  destroyed() {
    this.quickAddInstance = null
  },
  mounted() {
    this.loading = false
  },
  methods: {
    async clickAddHandle() {
      if (this.isSoldOut) return
      const { goods_id, goods_img, mall_code, mobileVerticalView } = this.curData
      const target = this.$parent?.$el
      const imgRatio = await getImageRatio(goods_img)
      this.quickAddInstance = await this.$quickAdd.open({
        index: this.index,
        goods_id,
        imgRatio,
        mallCode: mall_code,
        selectedAttrIndex: this.externalSizeInfoIndex,
        showBestDealLabel: !!this.config?.showBestDealLabel,
        needCartTagTipsUpdate: false, // TR-17838增加, 不需要快加车内部去触发利诱相关的update方法
        showFollowBeltByOrigin: true,
        clickCallBack: {
          selectSizeDefault: params => {
            // 非用户选择的尺寸默认选中
            this.$emit('sizeClick', params)
          },
          // 尺寸选择
          selectSize: params => {
            this.$emit('sizeClick', params)
          },
          // 切换色块
          changeColor: (item = {}) => {
            const { goods_id } = this.curData
            if (goods_id == item?.goods_id) return
            this.$emit('updateGoods', item)
          },
          // 收藏状态
          toggleWishStatus: (wishStatus) => {
            this.$emit('updateWishStatus', wishStatus)
          },
          clickClose: () => {}
        },
        analysisConfig: {
          code: 'productList',
          sourceTarget: target || null,
          sa: {
            mobileVerticalView,
            activity_from: this.config?.sa?.activity_from ?? 'goods_list'
          }
        }
      })

      if (this.quickAddInstance) {
        this.quickAddInstance?.$on('addToBagSuccess', this.debounceIt)
        // this.isOnAddToBagSuccess = true
      }
      // 点击加车这个动作触发埋点
      daEventCenter.triggerNotice({
        daId: '2-3-5',
        target
      })
    },
    // 处理重复注册addToBagSuccess， 导致的问题
    debounceIt: debounce({ func: function ({ target }) {
      this.$emit('addToBagSuccess', { target })
      this.quickAddInstance.$off('addToBagSuccess')
    }, wait: 20 }),
    handleToggleWishStatus(status) {
      this.$emit('updateWishStatus', status)
    }
  }
}
</script>

<style lang="less">
.mini-btn__container {
  display: inline-flex;
  width: 100%;
  padding: 8/75rem 24/75rem;
  &.loading {
    opacity: 0;
  }
  /* stylelint-disable-next-line selector-class-pattern */
  .quick_add__btns {
    width: 100%;
    display: inline-flex;
    align-items: center;
  }
  .c-btn-save {
    padding-right: 4px;
    .c-btn-save_save-icon {
      .font-dpr(64px) !important;
    }
  }
  .add-btn {
    flex: 1;
    height: 80 / 75rem;
    margin-left: 14/75rem;
    &.disabled {
      background: #bbbbbb;
    }
  }
}
</style>
