var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"base-labels__container"},[(_vm.curData && _vm.pretreatInfo && _vm.createComp)?_c('MiniTagsBaseBottom',{attrs:{"config":{
      showLocalSeller: true,
      showQuickShip: true,
      showPromotion: true,
      showSellingPoint: true,
      showUserAct: true,
      showBadge: true,
      configReady: _vm.configReady
    },"labels-show":_vm.labelsShow,"show-selling-points-on-label":true,"pretreat-info":_vm.pretreatInfo,"language":_vm.language,"is-sold-out":_vm.isSoldOut,"constant-data":_vm.constantData,"cur-data":_vm.curData,"goods-bottom-badge-style":_vm.goodsBottomBadgeStyle,"show-one-line":true,"cal-params":_vm.calParams,"show-grey-selling-point":_vm.config.showGreySellingPoint},on:{"exposeSellingPoint":function($event){return _vm.$emit('finishedCalBottom', true)}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }