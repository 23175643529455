<template>
  <div class="mini-exclusive__container">
    <div
      class="mini-exclusive__price"
      tabindex="0"
      role="none"
      :aria-label="exclusivePromotionPrice.amountWithSymbol"
    >
      <span>{{ exclusivePromotionPrice.amountWithSymbol }}</span>
      <em
        class="vip-exclusive-tag_color-black"
        :class="[cssRight ? 'vip-exclusive-tag__ar' : 'vip-exclusive-tag']"
      ></em>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MiniPriceExclusive',
  props: {
    exclusivePromotionPrice: {
      type: Object,
      default: () => {}
    },
    constantData: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    cssRight() {
      return this.constantData.GB_cssRight
    }
  }
}
</script>

<style lang="less">
.mini-exclusive__container {
  margin-left: 8/75rem;
  .mini-exclusive__price {
    font-weight: 700;
    .font-dpr(24px);
    line-height: 28/75rem;
    color: #a78a45;
  }
}
</style>
<style lang="less">
@s3_icon: '/pwa_dist/images/sheinvip2/s3_icon-7c5e305bc1.png';
@s3_icon_ar: '/pwa_dist/images/sheinvip2/s3_icon_ar-827f0ce0d6.png';

.vip-exclusive-tag,
.vip-exclusive-tag__ar {
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 3.23rem .77rem;
  width: .85rem;
  height: .27rem;
  &_color-yellow {
    background-position: -1.45rem -.25rem;
  }
  &_color-black {
    background-position: -.48rem -.25rem;
  }
}
.vip-exclusive-tag {
  background-image: url(@s3_icon);
}
.vip-exclusive-tag__ar {
  background-image: url(@s3_icon_ar);
  vertical-align: -0.026667rem;
}
</style>
