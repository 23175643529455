export default {
  data () {
    return {
      constantData: {}
    }
  },
  created () {
    const {
      LAZY_IMG,
      LAZY_IMG_PIXEL,
      IMG_LINK,
      GB_cssRight, 
      IS_RW,
      langPath,
      PUBLIC_CDN = '',
      lang,
      currency,
      currencies,
      LAZY_IMG_SOLID_COLOR
    } = typeof window === 'undefined' ? this.locals || {} : gbCommonInfo
      
    this.constantData = Object.assign({}, this.constantData, { LAZY_IMG, LAZY_IMG_PIXEL, LAZY_IMG_SOLID_COLOR, IMG_LINK, GB_cssRight, IS_RW, langPath, PUBLIC_CDN, lang, currency, currencies })
  },
}
