const actions = {
  initState ({ getters, commit }, payload) {
    commit('setSuggestedSalePriceType', payload.suggestedSalePriceType)
    commit('setAbtInfo', payload.listAbtResult)
    commit('setHooksGoods', payload.hooksGoods)
    commit('setLanguage', payload.language)
    commit('setPageType', payload.pageType)
    commit('initSheinClubInfo', payload.sheinClubInfo)
    commit('initCatInfo', payload.cat_info)
    commit('initCccConfig', payload.cccConfig)
    commit('initAtomicFields', payload.atomicParams.fields)
    commit('initItemConfig', getters)
    commit('initGoodsItemInfo', getters)
  }
}

export default actions
