<template>
  <div
    v-if="tabList.length"
    class="recommend-tabs"
  >
    <SSticky
      class="recommend-tabs__sticky"
      :type="'fixed'"
      :offset="1.17"
      :style="{
        'z-index': 9
      }"
      :fixed-work="fixedWork"
    >
      <STab
        v-model="activeTabIndex"
        class="recommend-tabs__body"
        type="auto"
        align="left"
        position="content"
        un-selected-color="#767676"
        @change="handleChange"
      >
        <STabItem
          v-for="(item, index) in tabList"
          :id="item.item_id"
          :key="index"
          v-expose="{id:'1-65-1-4', data: {tab: item.item_id ? String(item.item_id) : '-'}}"
        >
          {{ item.item_name }}
        </STabItem>
      </STab>
    </SSticky>
  </div>
</template>

<script>
import { Tab as STab, TabItem as STabItem, Sticky as SSticky } from '@shein/sui-mobile'
import { expose } from 'public/src/pages/common/analysis/directive.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
const daEventExpose = daEventCenter.getExposeInstance()
daEventCenter.addSubscriber({ modulecode: '1-65-1' })
export default {
  name: 'RecommendFlowTabs',
  components: {
    STab,
    STabItem,
    SSticky
  },
  directives: {
    expose,
  },
  inject: ['advertisingAnalysis'],
  props: {
    tabs: {
      type: Array,
      default() {
        return []
      }
    },
  },
  data() {
    return {
      activeTabIndex: 0,
      isFirst: true,
      isMounted: false,
    }
  },
  computed: {
    advertisingAnalysis() {
      return this.$root.advertisingAnalysis
    },
    // 水合之前只展示5个tab
    tabList() {
      return this.isMounted ? this.tabs : this.tabs.slice(0, 5)
    },
  },
  watch: {
    'tabs.length': {
      handler(val) {
        if (val) {
          this.activeTabIndex = this.tabs[0].item_id
        }
      },
      immediate: true,
    },
  },
  activated() {
    // 重进页面重新曝光
    !this.isFirst && daEventExpose?.resetAll?.()
    this.isFirst = false
  },
  mounted() {
    this.isMounted = true
  },
  methods: {
    handleChange({ val }) {
      // 点击埋点
      this.advertisingAnalysis.clickRecommendTabs({
        tab: val || '-',
      })
      this.$emit('tabChange', val)
    },
    fixedWork(work) {
      this.$emit('changeSticky', !!work)
    }
  }
}
</script>

<style lang="less">
.recommend-tabs {
  &__sticky {
    // 组件内部用了style-component设置了z-index=1 意义为何
    z-index: @zindex-header !important; /* stylelint-disable-line declaration-no-important */
  }
}
</style>
