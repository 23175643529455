<template>
  <section
    class="mini-page__price"
    :class="{ 'special-bg': isSpecialFlash }"
  >
    <template v-if="isSpecialFlash">
      <h3 class="price-special">
        {{ flashTypeText }}
      </h3>
    </template>
    <div class="price-container">
      <!-- Sale Price -->
      <div
        v-ada="{level: 1, pos: 0}"
        class="price-from"
        :class="{
          special: !standardPrice && isSpecialFlash,
          sheinclub: !standardPrice && isSheinClubColor,
          single: standardPrice || (!hasDiscount && !retentionConfig.show),
        }"
        :aria-label="salePriceText"
        role="text"
      >
        <template v-if="!standardPrice && isNormalFlash">
          <i class="iconfont icon-flashsalepx flash-icon"></i>
        </template>

        <span v-else-if="!standardPrice &&iconList.length">
          <i
            v-for="(item, index) in iconList"
            :key="index"
            :class="iconClass"
          ></i>
        </span>
        {{ salePriceText }}
      </div>

      <template v-if="!standardPrice">
        <!-- 划线价 -->
        <div
          v-if="hasDiscount && !showSheinClub"
          v-ada="{level: 1, pos: 0}"
          class="price-sale"
          :aria-label="curPrice.retailPrice.amountWithSymbol"
          role="text"
        >
          {{ curPrice.retailPrice.amountWithSymbol }}
        </div>

        <!-- 折扣标记 -->
        <div
          v-if="showDiscount && !showSheinClub"
          aria-hidden="true"
          class="price-sale__discount"
          :class="{ 
            'special-discount': isSpecialFlash || config.showNewDiscountLabel,
            'special-discount-new': config.showNewDiscountLabel
          }"
        >
          <template v-if="lang!='il'">
            -
          </template>
          {{ discountValue }}%
        </div>

        <!-- S3 -->
        <MiniPriceExclusive
          v-if="isExclusivePrice"
          :constant-data="constantData"
          :exclusive-promotion-price="exclusivePromotionPrice"
        />

        <!-- SHEIN CLUB -->
        <MiniSheinClubPrice
          v-if="showSheinClub"
          :constant-data="constantData"
          :retail-price="retailPrice"
          :is-shein-club-member="isSheinClubMember"
          :shein-club-promotion-info="sheinClubPromotionInfo"
        />
      </template>
    </div>

    <!-- Comment -->
    <div class="price-right">
      <ProductItemStarComment
        v-if="starVisible.hasStarRating || starVisible.hasStarComment"
        :cur-data="curData"
        :show-star-rating="showStarRating"
        :star-rating-style="starRatingStyle"
      />
    </div>
  </section>
</template>

<script>
const { lang } = gbCommonInfo
import MiniPriceExclusive from './ExclusivePrice'
import MiniSheinClubPrice from './ShinClubPrice'
import ProductItemStarComment from 'public/src/pages/components/product/item_v2/components/ProductItemStarComment.vue'
import { getStarVisible } from 'public/src/pages/components/product/item_v2/js/utils'

export default {
  name: 'MiniDetailPrice',
  components: {
    MiniPriceExclusive,
    MiniSheinClubPrice,
    ProductItemStarComment
  },
  props: {
    config: {
      type: Object,
      default: () => {}
    },
    curData: {
      type: Object,
      default: () => {}
    },
    language: {
      type: Object,
      default: () => {}
    },
    curPrice: {
      type: Object,
      default: () => {}
    },
    constantData: {
      type: Object,
      default: () => {}
    },
    pretreatInfo: {
      type: Object,
      default: () => {}
    },
    sheinClubInfo: {
      type: Object,
      default: () => {}
    },
    showStarRating: {
      type: Boolean,
      default: false
    },
    retentionConfig: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      lang
    }
  },
  computed: {
    standardPrice() {
      return !!this.config.standardPrice
    },
    starVisible () {
      return getStarVisible(this.config, this.curData.starComment)
    },
    hasDiscount() {
      const { isSpecialFlash } = this
      if (isSpecialFlash) return true
      return (
        this.curPrice?.salePrice?.amount != this.curPrice?.retailPrice?.amount
      )
    },
    retailPrice() {
      return this.curPrice.retailPrice
    },
    salePriceText() {
      const { sheinClubPrice, isSheinClubMember, isSpecialFlash, flashPrice, curPrice } = this
      let salePrice = ''
      if (sheinClubPrice?.amountWithSymbol && isSheinClubMember) {
        salePrice = sheinClubPrice?.amountWithSymbol
      } else if (isSpecialFlash) {
        salePrice = flashPrice
      } else {
        salePrice = curPrice?.salePrice?.amountWithSymbol
      }
      return salePrice
    },
    showDiscount() {
      return this.hasDiscount && this.discountValue
    },
    exclusivePromotionPrice() {
      return this.curData.exclusivePromotionPrice || null
    },
    // 普通闪购
    isNormalFlash() {
      if (this.standardPrice) return null
      return this.curData?.promotionInfo?.find?.(
        _ => _.flash_type == 1 && _.typeId == 10
      )
    },
    // 是新型闪购（新人，专属）
    newFlashPromotion() {
      return this.pretreatInfo?.newFlashPromotion || null
    },
    isSpecialFlash() {
      // 特殊闪购
      return [2, 3].includes(this.flashType)
    },
    flashType() {
      return this.newFlashPromotion?.flash_type
    },
    flashPrice() {
      return this.newFlashPromotion?.price?.amountWithSymbol
    },
    flashDiscount() {
      return this.curData?.newFlashPromotion?.discount_percent || 0
    },
    flashTypeText() {
      return this.flashType == 2
        ? this.language.SHEIN_KEY_PWA_21928
        : this.flashType == 3
          ? this.language.SHEIN_KEY_PWA_21932
          : ''
    },
    isExclusivePrice() {
      return !!this.exclusivePromotionPrice?.amountWithSymbol
    },
    sheinClubPrice() {
      return this.sheinClubPromotionInfo?.price || {}
    },
    sheinClubPromotionInfo() {
      return this.curData?.sheinClubPromotionInfo || null
    },
    showSheinClub() {
      return !!this.sheinClubPromotionInfo
    },
    sheinClubRetailPrice() {
      const { amount: sheinClubPriceAmount } = this.sheinClubPrice || {}
      const {
        amount: retailPriceAmount,
        amountWithSymbol: retailAmountWithSymbol
      } = this.retailPrice || {}
      return Number(sheinClubPriceAmount) < Number(retailPriceAmount)
        ? retailAmountWithSymbol
        : ''
    },
    isSheinClubColor() {
      const { showSheinClub, isSheinClubMember } = this
      return showSheinClub && isSheinClubMember
    },
    isSheinClubMember() {
      return !!this.sheinClubInfo?.isPaid
    },
    isMoreDiscount() {
      return this.iconList?.['isMoreDiscount']
    },
    iconList() {
      if (this.standardPrice) return []
      return this.curData?.promotionInfo?.filter?.(item => item.typeId == 3) || []
    },
    iconClass() {
      return ['normal-icon', 'iconfont', 'icon-limitedoff-new']
    },
    discountValue() {
      const { curPrice, flashDiscount } = this
      return flashDiscount || curPrice.unit_discount
    },
    starRatingStyle() {
      return {
        fontWeight: 700,
        color: '#666666'
      }
    }
  }
}
</script>

<style lang="less">
.mini-page__price {
  position: relative;
  padding: 10/75rem 0 0 0;
  &.special-bg::after {
    content: "";
    position: absolute;
    width: calc(~"100% + .4266667rem");
    height: 100%;
    left: -16/75rem;
    top: 0;
    background: @sui_color_welfare_bg;
    z-index: @zindex-hack;
  }
  .price-container {
    position: relative;
    display: inline-flex;
    align-items: center;
    z-index: @zindex-out;
    .normal-icon {
      font-weight: normal;
      .font-dpr(40px);
    }
  }
  .price-special {
    position: relative;
    margin-bottom: 8/75rem;
    font-weight: 400;
    font-size: 12px;
    .font-dpr(24px);
    line-height: 28/75rem;
    color: #fa6338;
    z-index: @zindex-out;
  }
  .price-from {
    font-weight: 700;
    .font-dpr(40px);
    line-height: 48/75rem;
    color: @sui_color_discount;
    &.single {
      color: @sui_color_black;
    }
    &.special {
      color: #fa6338;
    }
    &.sheinclub {
      color: #873c00;
    }
    .flash-icon {
      color: #facf19;
    }
  }
  .price-right {
    float: right;
  }
  .price-sale {
    margin-left: 4/75rem;
    font-weight: 400;
    .font-dpr(22px);
    line-height: 26/75rem;
    text-decoration: line-through;
    color: @sui_color_gray_dark3;
  }
  .price-sale__discount {
    margin-left: 4/75rem;
    font-weight: 400;
    font-size: 10px;
    color: @sui_color_discount;
    height: 0.3733rem;
    line-height: 0.3467rem;
    padding: 0 0.0533rem;
    border: 1px solid @sui_color_discount;
    border-radius: 2px;
    direction: ltr /*rtl:ignore*/;
  }
  .special-discount {
    border-radius: 0;
    .font-dpr(20px);
    height: 32/75rem;
    line-height: 32/75rem;
    background-color: @sui_color_promo;
    color: @sui_color_white;
  }
  .special-discount-new {
    background-color: @sui_color_discount;
    color: #fff;
  }
}
</style>
