<template>
  <div class="mini-sheinclub__container">
    <template v-if="isSheinClubMember">
      <!-- 用户已经是付费会员 -->
      <ProductItemSheinClubDiscount
        v-if="sheinClubDiscountValue && isSheinClubMember"
        :discount-value="sheinClubDiscountValue"
        :public-cdn="publicCDN"
        :type="'small'"
      />
    </template>
    <template v-else>
      <div class="sheinclub-tags__container">
        <span class="sheinclub-tags__price">{{ sheinClubPrice }}</span>
        <span class="sheinclub-tags__split"></span>
        <span class="sheinclub-tags__icon">
          <i class="suiiconfont sui_icon_club_logo_16px"></i>
        </span>
        <img
          class="sheinclub-tags__bg"
          :src="`${publicCDN}/pwa_dist/images/pay_vip/paid_vip_card_bg-546c92402d.png`"
        />
      </div>
    </template>
  </div>
</template>
<script>
import ProductItemSheinClubDiscount from 'public/src/pages/components/product/item_v2/components/ProductItemSheinClubDiscount.vue'
export default {
  name: 'MiniSheinClubPrice',
  components: { ProductItemSheinClubDiscount },
  props: {
    constantData: {
      type: Object,
      default: () => {}
    },
    sheinClubPromotionInfo: {
      type: Object,
      default: () => {}
    },
    retailPrice: {
      type: Object,
      default: () => ({})
    },
    isSheinClubMember: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    publicCDN() {
      return this.constantData.PUBLIC_CDN
    },
    sheinClubDiscountValue() {
      return this.sheinClubPromotionInfo?.discountValue || ''
    },
    sheinClubPrice() {
      return this.sheinClubPromotionInfo?.price?.amountWithSymbol || ''
    }
  }
}
</script>

<style lang="less">
.mini-sheinclub__container {
  margin-left: 8/75rem;
  .sheinclub-tags {
    &__container {
      position: relative;
      padding: 0 12/75rem;
      display: inline-flex;
      align-items: center;
      color: #873c00;
    }
    &__price {
      font-family: "SF UI Display";
      font-style: normal;
      font-weight: 700;
      .font-dpr(24px);
      line-height: 28/75rem;
    }
    &__split {
      width: 0;
      margin: 0 8/75rem;
      height: 24/75rem;
      opacity: 0.2;
      border: 1px solid #873c00;
    }
    &__icon {
      .suiiconfont {
        .font-dpr(28px) !important;
      }
    }
    &__price, &__split, &__icon {
      position: relative;
      z-index: @zindex-hack;
    }
    &__bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
