<template>
  <div class="base-labels__container">
    <MiniTagsBaseBottom
      v-if="curData && pretreatInfo && createComp"
      :config="{
        showLocalSeller: true,
        showQuickShip: true,
        showPromotion: true,
        showSellingPoint: true,
        showUserAct: true,
        showBadge: true,
        configReady
      }"
      :labels-show="labelsShow"
      :show-selling-points-on-label="true"
      :pretreat-info="pretreatInfo"
      :language="language"
      :is-sold-out="isSoldOut"
      :constant-data="constantData"
      :cur-data="curData"
      :goods-bottom-badge-style="goodsBottomBadgeStyle"
      :show-one-line="true"
      :cal-params="calParams"
      :show-grey-selling-point="config.showGreySellingPoint"
      @exposeSellingPoint="$emit('finishedCalBottom', true)"
    />
  </div>
</template>

<script>
import MiniTagsBaseBottom from './MiniTagsBaseBottom'
export default {
  name: 'MiniTagsBaseLabels',
  components: { MiniTagsBaseBottom },
  props: {
    language: {
      type: Object,
      default: () => {}
    },
    curData: {
      type: Object,
      default: () => {}
    },
    pretreatInfo: {
      type: Object,
      default: () => {}
    },
    constantData: {
      type: Object,
      default: () => {}
    },
    isSoldOut: {
      type: Boolean,
      default: false
    },
    config: {
      type: Object,
      default: () => {}
    },
    labelsShow: {
      type: Object,
      default: () => {}
    },
    goodsBottomBadgeStyle: {
      type: Object,
      default: () => {}
    },
    calParams: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      createComp: true, // 用于重新初始化组件
      configReady: false
    }
  },
  watch: {
    curData (val, oldVal) {
      if (Number(val.goods_id) !== Number(oldVal.goods_id)) {
        this.createComp = false
        this.$nextTick(() => {
          this.createComp = true
        })
      }
    },
  },
  mounted() {
    this.configReady = true
  }
}
</script>
