<template>
  <MiniPageList
    ref="productList"
    :locals="locals"
    :class="isSticky ? 'big-card-container__sticky' : 'big-card-container'"
    :show-cart-bag-icon="false"
    :list-abt-result="abtInfo"
    :config="productItemConfig"
    :analysis-data="analysisData"
    :goods="goods"
    :append-goods="goods"
    :shein-club-info="sheinClubInfo"
    :language="language"
    :goods-item-info="goodsItemInfo"
  />
</template>

<script>
import { mapState } from 'vuex'
import { storeModuleName } from 'public/src/pages/product_app/store/modules/advertising/index.js'
import MiniPageList from 'public/src/pages/rec_product_list/MiniPageList/index.vue'
import { HOOK_GOOD_CLICK_ID, HOOK_GOOD_EXPOSE_ID } from '../../analysis/index'
import { daEventCenter } from 'public/src/services/eventCenter/index'

let daEventExpose = daEventCenter.getExposeInstance()

export default {
  name: 'BigCard',
  components: {
    MiniPageList
  },
  inject: ['advertisingAnalysis'],
  data () {
    return {
      observer: null,
      isSticky: false,
      analysisData: {
        exposeCode: 'advertisingBigCard'
      }, 
    }
  },
  computed: {
    ...mapState(storeModuleName, [
      'catInfo',
      'abtInfo',
      'locals',
      'pageType',
      'itemConfig',
      'language',
      'sheinClubInfo',
      'hooksGoods',
      'goodsItemInfo',
      'atomicFields',
      'suggestedSalePriceType'
    ]),
    goods () {
      return this.hooksGoods ? [this.hooksGoods] : []
    },
    productItemConfig () {
      return {
        ...this.itemConfig,
        standardPrice: !!this.suggestedSalePriceType,
        itemDAEventExposeId: HOOK_GOOD_EXPOSE_ID,
        itemDAEventClickId: HOOK_GOOD_CLICK_ID
      }
    }
  },
  mounted () {
    this.getPretreatInfo()
    this.createVirtualDom()
    this.createObserveDom()
    this.addEvent()
  },
  beforeDestroy () {
    this.removeEvent()
  },
  methods: {
    async getPretreatInfo () {
      await this.goodsItemInfo?.getProductInfo({
        goods: this.goods,
        requestFields: this.atomicFields,
        itemConfig: this.itemConfig,
        pageKey: 'page_advertising',
        sceneKey: 'TWO_IN_A_ROW',
        onlyDealData: true, 
      })

      await this.goodsItemInfo.getRelateColorProductInfo({
        goods: this.goods,
      })

      this.initAnalysis()
    },
    initAnalysis () {
      this.$refs.productList.setAnalysisData('firstload')
      daEventExpose.subscribe({
        keycode: `${this.analysisData.exposeCode}\`${HOOK_GOOD_EXPOSE_ID}`,
        type: 'list', 
      })
    },
    resetAnalysis () {
      daEventExpose.reset(this.analysisData.exposeCode)
    },
    // 监听底部栏是否吸顶
    addEvent () {
      const dom = this.$refs.productList?.$el?.querySelector?.('.big-card--ovserve-dom')
      if (!dom || !IntersectionObserver) return

      this.observer = new IntersectionObserver(([e]) => {
        const { target, intersectionRatio } = e
        this.isSticky = intersectionRatio < 1 && target.getBoundingClientRect().top > 0
      }, {
        threshold: [0, 1]
      })
      this.observer.observe(dom)
    },
    removeEvent () { 
      const dom = this.$refs.productList?.$el?.querySelector?.('.big-card--ovserve-dom')
      if (!dom || !this.observer) return

      this.observer.unobserve(dom)
      this.observe.disconnect()
    },
    // 虚拟dom插入
    createVirtualDom () {
      const dom = document.createElement('div')
      const btnContainerDom = this.$el.querySelector('.mini-btn__container')
      btnContainerDom.style.width = btnContainerDom.clientWidth + 'px' // fixed下宽度会变化，需要设置宽度
      dom.className = 'big-card__virtual-dom'
      dom.style = `height:${this.$el.querySelector('.mini-btn__container')?.clientHeight || 0}px`
      this.$el.querySelector('.mini-detail__box').appendChild(dom)
    },
    createObserveDom () {
      const dom = document.createElement('div')
      dom.className = 'big-card--ovserve-dom'
      dom.style = 'height: 1px'
      this.$el.querySelector('.mini-detail__box').appendChild(dom)
    }
  }

}
</script>

<style lang="less">

.advertising-page-card {
  .mini-detail__container{
    background: #f3f3f3;
    margin-top: 1px solid #e1e1e1;
  }

  .big-card__virtual-dom {
    display: none;
  }

  .mini-btn__container {
    align-items: center;
  }
}

// 吸顶
.big-card-container__sticky {
  .big-card__virtual-dom {
    display: block;
  }
  .mini-btn__container {
    position: fixed;
    bottom: -1px;
    z-index: 10;
    background: #fff;
    border: 1px solid #fff;
    transform: translateZ(10px);
  }
}

.big-card-container .mini-btn__container {
  border-radius: 10px;
}
</style>
