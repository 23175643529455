var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"mini-page__price",class:{ 'special-bg': _vm.isSpecialFlash }},[(_vm.isSpecialFlash)?[_c('h3',{staticClass:"price-special"},[_vm._v("\n      "+_vm._s(_vm.flashTypeText)+"\n    ")])]:_vm._e(),_vm._v(" "),_c('div',{staticClass:"price-container"},[_c('div',{directives:[{name:"ada",rawName:"v-ada",value:({level: 1, pos: 0}),expression:"{level: 1, pos: 0}"}],staticClass:"price-from",class:{
        special: !_vm.standardPrice && _vm.isSpecialFlash,
        sheinclub: !_vm.standardPrice && _vm.isSheinClubColor,
        single: _vm.standardPrice || (!_vm.hasDiscount && !_vm.retentionConfig.show),
      },attrs:{"aria-label":_vm.salePriceText,"role":"text"}},[(!_vm.standardPrice && _vm.isNormalFlash)?[_c('i',{staticClass:"iconfont icon-flashsalepx flash-icon"})]:(!_vm.standardPrice &&_vm.iconList.length)?_c('span',_vm._l((_vm.iconList),function(item,index){return _c('i',{key:index,class:_vm.iconClass})}),0):_vm._e(),_vm._v("\n      "+_vm._s(_vm.salePriceText)+"\n    ")],2),_vm._v(" "),(!_vm.standardPrice)?[(_vm.hasDiscount && !_vm.showSheinClub)?_c('div',{directives:[{name:"ada",rawName:"v-ada",value:({level: 1, pos: 0}),expression:"{level: 1, pos: 0}"}],staticClass:"price-sale",attrs:{"aria-label":_vm.curPrice.retailPrice.amountWithSymbol,"role":"text"}},[_vm._v("\n        "+_vm._s(_vm.curPrice.retailPrice.amountWithSymbol)+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.showDiscount && !_vm.showSheinClub)?_c('div',{staticClass:"price-sale__discount",class:{ 
          'special-discount': _vm.isSpecialFlash || _vm.config.showNewDiscountLabel,
          'special-discount-new': _vm.config.showNewDiscountLabel
        },attrs:{"aria-hidden":"true"}},[(_vm.lang!='il')?[_vm._v("\n          -\n        ")]:_vm._e(),_vm._v("\n        "+_vm._s(_vm.discountValue)+"%\n      ")],2):_vm._e(),_vm._v(" "),(_vm.isExclusivePrice)?_c('MiniPriceExclusive',{attrs:{"constant-data":_vm.constantData,"exclusive-promotion-price":_vm.exclusivePromotionPrice}}):_vm._e(),_vm._v(" "),(_vm.showSheinClub)?_c('MiniSheinClubPrice',{attrs:{"constant-data":_vm.constantData,"retail-price":_vm.retailPrice,"is-shein-club-member":_vm.isSheinClubMember,"shein-club-promotion-info":_vm.sheinClubPromotionInfo}}):_vm._e()]:_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"price-right"},[(_vm.starVisible.hasStarRating || _vm.starVisible.hasStarComment)?_c('ProductItemStarComment',{attrs:{"cur-data":_vm.curData,"show-star-rating":_vm.showStarRating,"star-rating-style":_vm.starRatingStyle}}):_vm._e()],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }