<template>
  <div
    ref="productList"
    class="mini-pages__container j-da-event-box j-product-list-info"
    code="productList"
    :data-tag_ids="catInfo.tag_ids"
    :data-cat="catInfo.child_cat_id"
    :data-attr_str="catInfo.attr_ids"
    :data-min-pri="catInfo.min_price"
    :data-max-pri="catInfo.max_price"
    data-active-from="goods_list"
  >
    <!-- SSR -->
    <template v-for="(product, index) in goods.slice(0, 3)">
      <MiniDetail
        :ref="`MiniDetailItem_${index}`"
        :key="`mini-page__${product.goods_id}`"
        :da-event-expose="config.itemDAEventExposeId"
        :index="index"
        :config="config"
        :locals="locals"
        :language="language"
        :product-info="product"
        :goods-item-info="goodsItemInfo"
        :shein-club-info="sheinClubInfo"
        :big-card-style="bigCardStyle"
        :scroll-fix="scrollFix"
        @addToBagSuccess="addToBagSuccess"
      />
    </template>
    <!-- CSR -->
    <ClientOnly>
      <template v-for="(product, index) in goods.slice(3)">
        <MiniDetail
          :ref="`MiniDetailItem_${index + 3}`"
          :key="`mini-page__${product.goods_id}`"
          :da-event-expose="config.itemDAEventExposeId"
          :index="index + 2"
          :config="config"
          :locals="locals"
          :language="language"
          :product-info="product"
          :goods-item-info="goodsItemInfo"
          :shein-club-info="sheinClubInfo"
          :big-card-style="bigCardStyle"
          :scroll-fix="scrollFix"
          @addToBagSuccess="addToBagSuccess"
        />
      </template>
    </ClientOnly>
    <CartBag 
      v-if="showCartBag" 
      ref="cartBag"
    />
  </div>
</template>

<script>
import ClientOnly from 'vue-client-only'
import MiniDetail from 'public/src/pages/components/product/mini-detail/container.vue'
import { scrollFix as scroll_fix } from 'public/src/pages/common/utils/index.js'
export default {
  name: 'MiniPageList',
  components: { 
    MiniDetail, 
    ClientOnly,
    CartBag: () => import(/* webpackChunkName: "CartBag" */'public/src/pages/product_list_v2/components/CartBag/index.vue'), 
  },
  props: {
    locals: {
      type: Object,
      default: () => {}
    },
    listAbtResult: {
      type: Object,
      default: () => {}
    },
    goods: {
      type: Array,
      default: () => []
    },
    language: {
      type: Object,
      default: () => {}
    },
    config: {
      type: Object,
      default: () => {}
    },
    bigCardStyle: {
      type: [String, null],
      default: ''
    },
    goodsItemInfo: {
      type: Object,
      default: () => {}
    },
    catInfo: {
      type: Object,
      default: () => ({})
    },
    analysisData: {
      type: Object,
      default: () => {}
    },
    appendGoods: {
      type: Array,
      default: () => []
    },
    sheinClubInfo: {
      type: Object,
      default: () => {}
    },
    showCartBagIcon: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      scrollFix: () => {}
    }
  },
  computed: {
    showCartBag() {
      return this.showCartBagIcon && this.listAbtResult?.ListAddToBag?.p?.ListAddToBag === 'ListBag' && !this.locals?.IS_RW
    }
  },
  beforeDestroy() {
    this.scrollFix?.(false)
  },
  deactivated() {
    this.scrollFix?.(false)
  },
  mounted() {
    this.scrollFix = scroll_fix()
  },
  methods: {
    addToBagSuccess({ target }) {
      this.$refs?.cartBag?.drop?.(target)
    },
    setAnalysisData (requestType) {
      if (requestType !== 'nexpage') {
        const listEl = this.$refs.productList
        listEl.setAttribute('da-expose-code', this.analysisData?.exposeCode || '')
        listEl.setAttribute('data-poskey', this.analysisData?.scenes || '')
        listEl.setAttribute('data-traceid', this.analysisData?.traceId || '')
        listEl.setAttribute('data-request_ext', this.analysisData?.request_ext || '')
        listEl.setAttribute('data-dimension', this.analysisData?.saDimension || '')
      }
      const keymap = this.appendGoods.reduce((curr, next)=> { curr[next.goods_id] = true; return curr }, {})
      this.goods.forEach((item, index) => {
        if (keymap[item.goods_id]) {
          const itemInstance = this.$refs?.[`MiniDetailItem_${index}`]?.[0]
          itemInstance?.setElAttr()
        }
      })
      return `${this.config.itemDAEventExposeId}`
    },
    exposeHandle(targets) {
      targets.forEach(_ => {
        const index = _?.dataset?.index
        const itemInstance = this.$refs?.[`MiniDetailItem_${index}`]?.[0]
        itemInstance.initInstance()
      })
    }
  }
}
</script>

<style lang="less">
.mini-pages__container {
  padding-top: 10/75rem;
}
</style>
