<template>
  <div class="mini-goodsname__container">
    <h1 class="minigoods-name">
      <span
        v-if="newTag"
        aria-hidden="true"
        class="new-flag"
      >{{ newTag.name }}</span>
      <span
        v-else-if="hotTag"
        aria-hidden="true"
        class="hot-flag"
      >{{ language?.SHEIN_KEY_PWA_25731 || 'Hot' }}</span>
      <span
        role="text"
        tabindex="1"
        :aria-label="goodsName"
      >{{ goodsName }}</span>
    </h1>
    <i class="suiiconfont sui_icon_more_right2_16px"></i>
  </div>
</template>

<script>
export default {
  name: 'MiniGoodsName',
  props: {
    language: {
      type: Object,
      default: () => {}
    },
    curData: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    // 图片左上角角标
    newTag() {
      const { badges } = this.curData
      if (!badges) return null
      const tag = badges.P_UPPER_LEFT && badges.P_UPPER_LEFT[0]
      if(tag?.['name-en'] == 'New') {
        return tag
      }
      return false
    },
    // 大卡片hot标,没有左上角的情况
    hotTag() {
      const { tspLabels } = this.curData
      return tspLabels?.hot_color == 1
    },
    goodsName() {
      return this.curData?.goods_name
    }
  }
}
</script>

<style lang="less">
.mini-goodsname__container {
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .minigoods-name {
    font-weight: 400;
    .font-dpr(24px);
    // line-height: 12/75rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    .new-flag {
      padding: 0 10/75rem;
      font-weight: 700;
      font-size: 10px;
      .font-dpr(20px);
      line-height: 24/75rem;
      background: #EDFFEE;
      color: @sui_color_new;
    }
    .hot-flag {
      background: #FA6338;
      color:#FFF;
      padding: 0.004rem 0.08rem;
    }
  }
}
</style>
