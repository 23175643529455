<template>
  <div
    v-if="showMiniTags"
    ref="TAGS_CONTAINER_REF"
    class="mini-tags__container"
    :style="{opacity: tagsReady ? 1 : 0}"
  >
    <!-- 榜单 -->
    <ProductItemRankingList
      v-if="rankingList"
      ref="RANK_REF"
      class="mini-tags__rank"
      :ranking-list="rankingList"
    />

    <!-- 基础标签 -->
    <MiniTagsBaseLabels
      v-if="showBaseLabels"
      ref="BASE_LABELS_REF"
      class="mini-tags__base"
      :language="language"
      :cur-data="curData"
      :pretreat-info="pretreatInfo"
      :constant-data="constantData"
      :is-sold-out="isSoldOut"
      :labels-show="labelsShow"
      :goods-bottom-badge-style="goodsBottomBadgeStyle"
      :config="config"
      :cal-params="{
        container: 'mini-tags__container',
        safeWidth: '48'
      }"
      @finishedCalBottom="finishedCalBottom"
    />

    <!-- 用户行为标签 -->
    <ProductItemUserBehaviorLabel
      v-if="userBehaviorLabelLang && showUserBehavior"
      ref="USER_BEHAVIOR_REF"
      :label-lang="userBehaviorLabelLang"
      :user-behavior-label-info="userBehaviorLabelInfo"
    />

    <!-- 用户评论关键词标签 -->
    <ProductItemUserCommentKeywordsLabel
      v-if="userCommentKeywordsLang && showUserCommentKeywords"
      ref="USER_COMMENT_REF"
      :user-comment-keywords-label-info="userCommentKeywordsLabelInfo"
    />
  </div>
</template>

<script>
import ProductItemUserBehaviorLabel from 'public/src/pages/components/product/item_v2/components/ProductItemUserBehaviorLabel.vue'
import ProductItemRankingList from 'public/src/pages/components/product/item_v2/components/ProductItemRankingList.vue'
import ProductItemUserCommentKeywordsLabel from 'public/src/pages/components/product/item_v2/components/ProductItemUserCommentKeywordsLabel.vue'
import MiniTagsBaseLabels from './MiniTagsBaseLabels'
export default {
  name: 'MiniTags',
  components: {
    MiniTagsBaseLabels,
    ProductItemRankingList,
    ProductItemUserBehaviorLabel,
    ProductItemUserCommentKeywordsLabel
  },
  props: {
    language: {
      type: Object,
      default: () => {}
    },
    curData: {
      type: Object,
      default: () => {}
    },
    pretreatInfo: {
      type: Object,
      default: () => {}
    },
    constantData: {
      type: Object,
      default: () => {}
    },
    isSoldOut: {
      type: Boolean,
      default: false
    },
    config: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      showBaseLabels: true,
      showUserBehavior: true,
      showUserCommentKeywords: true,
      tagsReady: false
    }
  },
  computed: {
    mobileVerticalView() {
      return this.curData?.mobileVerticalView
    },
    userBehaviorLabelInfo() {
      return this.mobileVerticalView?.userBehaviorLabels?.[0]
    },
    userBehaviorLabelLang() {
      return this.userBehaviorLabelInfo?.labelLang
    },
    userCommentKeywordsLabelInfo() {
      return this.pretreatInfo?.mobileVerticalView?.userCommentKeywordsLabel
    },
    userCommentKeywordsLang() {
      return this.config.showUserCommentKeywordsLabel && this.pretreatInfo?.mobileVerticalView?.userCommentKeywordsLabel?.labelLang
    },
    rankingList() {
      return this.pretreatInfo?.mobileVerticalView?.rankingList || null
    },
    showMiniTags() {
      const { userBehaviorLabelLang, rankingList, localMallName, quickShip, priceCut, promotionLabel, sellingPoints, cccTspBadges } = this
      return rankingList || userBehaviorLabelLang || localMallName || quickShip || priceCut || promotionLabel || sellingPoints?.length || cccTspBadges
    },
    labelsShow() {
      // this.promotionInfo?.length && console.log('config ===', {
      //   // flashTypeText: this.config.showNewFlashLabel && this.flashTypeText,
      //   localMallName: this.config.showLocalSeller && this.localMallName,
      //   quickShip: this.config.showQuickShip && this.curData.quickship,
      //   priceCut: this.config.showPriceCut && this.pretreatInfo?.priceCut,
      //   promotionLabel: this.config.showPromotion && this.promotionInfo?.lable || '',
      //   sellingPoints: this.sellingPoints,
      //   cccTspBadges: this.config.showBadge  && this.goodsBottom?.text,
      //   stockLeft: this.isRW && this.config.showLeftStock && this.pretreatInfo?.stockLeft,
      // })

      const { localMallName, quickShip, priceCut, promotionLabel, sellingPoints, cccTspBadges } = this
      return { localMallName, quickShip, priceCut, promotionLabel, sellingPoints, cccTspBadges }
    },
    cccTspBadges () {
      return this.config.showBadge  && this.goodsBottom?.text
    },
    quickShip () {
      return this.config.showQuickShip && this.curData.quickship
    },
    priceCut () {
      return this.config.showPriceCut && this.pretreatInfo?.priceCut
    },
    promotionInfo () {
      let promotionInfo = this.pretreatInfo?.mixPromotionInfo || []
      return promotionInfo && promotionInfo.length > 0 && promotionInfo[0] || null
    },
    promotionLabel () {
      return this.config.showPromotion && this.promotionInfo?.lable || ''
    },
    // 商品下方角标样式
    goodsBottomBadgeStyle () {
      const style = {}
      const fontColor = this.goodsBottom?.fontColor
      const backgroundColor = this.goodsBottom?.backgroundColor
      if(fontColor) style.color = fontColor
      if(backgroundColor) style.backgroundColor = backgroundColor

      return style
    },
    // 卖点
    sellingPoints() {
      return this.pretreatInfo?.sellingPoints
    },
    localMallName() {
      const { localMall, language_mall_name } = this.pretreatInfo?.mallTagsInfo || {}
      return this.config.showLocalSeller && localMall && language_mall_name
    },
    // 商品下方ccc-tsp角标
    goodsBottom () {
      return this.pretreatInfo?.goodsBottom || null
    },
  },
  async mounted() {
    await this.$nextTick()
    this.tagsReady = true
  },
  methods: {
    calTagsWith() {
      /**
     * showBaseLabels: true,
      showUserBehavior: true
      showUserCommentKeywords: true
     */
      const SAFE_WIDTH = 48 // 安全距离
      const MARGIN = 4 // 边距
      const CONTAINER_WIDTH =
        this.$refs['TAGS_CONTAINER_REF']?.clientWidth || 0
      if (!CONTAINER_WIDTH) return

      const RANK_EL = this.$el?.querySelector('.mini-tags__rank')
      const RANK_WIDTH = RANK_EL ? (RANK_EL?.clientWidth ?? 0) + MARGIN : 0
      // 如果 小于安全边距 其他的就别展示了

      if (CONTAINER_WIDTH - RANK_WIDTH < SAFE_WIDTH) {
        this.showBaseLabels = false
        this.showUserBehavior = false
        this.showUserCommentKeywords = false
        return
      }

      const BASE_LABEL_EL = this.$refs['BASE_LABELS_REF']?.$el
      const BASE_LABEL_WIDTH = BASE_LABEL_EL ? BASE_LABEL_EL?.offsetWidth + MARGIN : 0

      if (CONTAINER_WIDTH - RANK_WIDTH < BASE_LABEL_WIDTH) {
        this.showUserBehavior = false
        this.showUserCommentKeywords = false
        return
      }

      // 行为标签处理
      const USER_BEHAVIOR_EL = this.$el?.querySelector?.('.product-item__user-behavior')
      const USER_BEHAVIOR_WIDTH = USER_BEHAVIOR_EL ? USER_BEHAVIOR_EL?.clientWidth + MARGIN : 0
      if (
        USER_BEHAVIOR_EL && CONTAINER_WIDTH - RANK_WIDTH - BASE_LABEL_WIDTH > SAFE_WIDTH
      ) {
        this.setOverflow(USER_BEHAVIOR_EL, USER_BEHAVIOR_WIDTH)
      } else {
        this.showUserBehavior = false
      }

      if (CONTAINER_WIDTH - RANK_WIDTH - USER_BEHAVIOR_WIDTH < BASE_LABEL_WIDTH) {
        this.showUserCommentKeywords = false
        return
      }

      // 评论标签处理
      const USER_COMMENT_EL = this.$el?.querySelector?.('.product-item__user-commentLabel')
      const USER_COMMENT_WIDTH = USER_COMMENT_EL ? USER_COMMENT_EL?.clientWidth + MARGIN : 0
      if (
        USER_COMMENT_EL && CONTAINER_WIDTH - RANK_WIDTH - BASE_LABEL_WIDTH - USER_BEHAVIOR_WIDTH > SAFE_WIDTH
      ) {
        this.setOverflow(USER_COMMENT_EL, USER_COMMENT_WIDTH)
      } else {
        this.showUserCommentKeywords = false
      }

    },
    setOverflow(el, width) {
      el.style.maxWidth = `${width}px`
      el.style.overflow = 'hidden'
    },
    finishedCalBottom() {
      this.$nextTick(() => {
        this.calTagsWith()
      })
    },
  }
}
</script>

<style lang="less">
.mini-tags__container {
  position: relative;
  margin-top: -16/75rem;
  margin-bottom: 10 / 75rem;
  width: 100%;
  display: inline-flex;
  align-items: center;
  flex-wrap: nowrap;
  overflow: hidden;
  .mini-tags__rank,
  .mini-tags__base {
    margin-right: 8 / 75rem;
  }
  .product-item__label_common {
    margin-top: 0;
  }
}
</style>
