export const checkGoodsStock = (goods) => {
  if (!goods) {
    return false
  }
  const { stock, is_on_sale } = goods || {}
  if (is_on_sale === '0' || stock <= 0) {
    return false
  }
  return true
}
