<template>
  <!-- 大卡片标签 目前是基于列表的标签扩展的 后面需要做一套大卡片的专用标签集合 -->
  <div
    ref="goodsBottomEl"
    class="mini-tags__goods-bottom"
  >
    <!-- 降价标识 例：US$2 cheaper than added -->
    <div
      v-if="showReducePrice"
      class="mini-tags__reduce-price"
    >
      {{ reducePriceLabel }}
    </div>
    <!-- 收藏属性 -->
    <div
      v-if="showSaleAttr"
      class="mini-tags__sale-attr"
    >
      {{ saleAttrLabel }}
    </div>
    <!-- 各种标签， 优先级：新型闪购 > 本地卖家，快速发货 二选一 > 减价（price cut） > promotion > 卖点 > cccTsp标签 > 剩余库存 -->
    <div
      v-if="showGoodsBottom"
      class="mini-tags__label"
      :class="{
        'mini-tags__label_two': isTwoLine,
        'mini-tags__label_small-margin': multiColumn
      }"
      :style="goodsBottomStyle"
    >
      <!-- 本地卖家 -->
      <div
        v-if="showLocalSeller"
        ref="localSellerEl"
        class="mini-tags__label_localseller mini-tags__label_common"
      >
        {{ labelsShow.localMallName }}
      </div>

      <!-- 快速发货 -->
      <div
        v-else-if="showQuickShip"
        ref="localSellerEl"
        class="mini-tags__label_quickship mini-tags__label_common"
      >
        {{ language.SHEIN_KEY_PWA_22276 }}
      </div>

      <!-- 减价（price cut） -->
      <div
        v-if="showPriceCut"
        ref="priceCutEl"
        class="mini-tags__label_pricecut mini-tags__label_common"
      >
        {{ language.SHEIN_KEY_PWA_18817 }}
      </div>

      <!-- promotion -->
      <!-- v-ada="{ level: adaLevel, pos: [index, 11] }" -->
      <div
        v-if="showPromotion"
        ref="promotionEl"
        role="contentinfo"
        class="mini-tags__label_promotion mini-tags__label_common"
        v-html="labelsShow.promotionLabel"
      ></div>

      <!-- 卖点 -->
      <template v-if="showSellingPointsOnLabel">
        <template v-for="(item, idx) in sellingPoints">
          <div
            v-if="item && finalSellingPointIndex[idx]"
            :key="item.tag_val_id"
            :ref="`sellingPointEl${idx}`"
            class="mini-tags__label_sellingpoint mini-tags__label_common"
            :style="showGreySellingPointStyle"
          >
            {{ item.tag_val_name_lang }}
          </div>
        </template>
      </template>

      <!-- ccc-tsp角标 -->
      <div
        v-if="showCccTspBadge"
        ref="cccTspBadgeEl"
        class="mini-tags__label_badge mini-tags__label_common"
        :style="goodsBottomBadgeStyle"
      >
        {{ labelsShow.cccTspBadges }}
      </div>

      <!-- 剩余库存, 0-10(不含首尾) 例: only 2 left -->
      <div
        v-if="showStockLeft"
        ref="stockLeftEl"
        class="mini-tags__label_stockleft mini-tags__label_common"
      >
        {{ labelsShow.stockLeft }}
      </div>
    </div>
    <!-- 用户行为数据标签 -->
    <div
      v-else-if="config.showUserAct && userActTip"
      class="mini-tags__userAct-label mini-tags__label_common"
    >
      {{ userActTip }}
    </div>
  </div>
</template>

<script>
import { template } from '@shein/common-function'

export default {
  name: 'ProductItemGoodsBottom',
  props: {
    config: {
      type: Object,
      default: () => ({})
    },
    pretreatInfo: {
      type: Object,
      default: () => ({})
    },
    language: {
      type: Object,
      default: () => ({})
    },
    showOneLine: {
      type: Boolean,
      default: false
    },
    isSoldOut: {
      type: Boolean,
      default: false
    },
    constantData: {
      type: Object,
      default: () => ({})
    },
    curData: {
      type: Object,
      default: () => ({})
    },
    multiColumn: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: -1
    },
    userBehaviorLabelInfo: {
      type: Object,
      default: () => ({})
    },
    userBehaviorLabelLang: {
      type: String,
      default: ''
    },
    showSellingPointsOnLabel: {
      type: Boolean,
      default: false
    },
    labelsShow: {
      type: Object,
      default: () => ({})
    },
    goodsBottomBadgeStyle: {
      type: Object,
      default: () => ({})
    },
    hideLabels: {
      type: Boolean,
      default: false
    },
    hasStarRating: {
      type: Boolean,
      default: false
    },
    calParams: {
      type: Object,
      default: () => {}
    },
    showGreySellingPoint: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      priceCutWithinWidth: true,
      promotionWithinWidth: true,
      cccTspBadgeWithinWidth: true,
      localSellerWithinWidth: true,
      stockLeftWithinWidth: true,
      finishBottomElCalcWidth: false,
      showSellingPointQuantity: 2, // 最多能展示的卖点标签数量
      finalSellingPointIndex: {}, // 根据宽度计算后，最终展示得下的卖点的index
      showTwoLine: false,
      showUserActLabel: ''
    }
  },
  computed: {
    showGoodsBottom() {
      let showSellingPoint = false
      if (
        this.showSellingPointsOnLabel &&
        Object.keys(this.finalSellingPointIndex).length
      ) {
        showSellingPoint = true
      }
      return (
        !this.hideLabels &&
        !this.isSoldOut &&
        (this.showPromotion ||
          showSellingPoint ||
          this.showCccTspBadge ||
          this.showLocalSeller ||
          this.showStockLeft ||
          this.showPriceCut ||
          this.showQuickShip)
      )
    },
    goodsBottomStyle() {
      return this.finishBottomElCalcWidth ? { opacity: 1 } : { opacity: 0 }
    },
    showGreySellingPointStyle() {
      return {
        color: this.showGreySellingPoint ? '#666666' : '',
        backgroundColor: this.showGreySellingPoint ? '#F6F6F6' : '',
      }
    },
    reducePriceLabel() {
      return this.pretreatInfo?.reducePrice?.label
    },
    showReducePrice() {
      return this.config.showReducePrice && this.reducePriceLabel
    },
    saleAttrLabel() {
      return this.pretreatInfo?.saleAttr?.label
    },
    showSaleAttr() {
      return this.config.showSaleAttr && this.saleAttrLabel
    },
    showPriceCut() {
      return this.labelsShow.priceCut && this.priceCutWithinWidth
    },
    showLocalSeller() {
      return this.labelsShow.localMallName && this.localSellerWithinWidth
    },
    showQuickShip() {
      return this.labelsShow.quickShip && this.localSellerWithinWidth
    },
    showPromotion() {
      return this.labelsShow.promotionLabel && this.promotionWithinWidth
    },
    // 卖点
    sellingPoints() {
      return this.labelsShow?.sellingPoints || []
    },
    showCccTspBadge() {
      return this.labelsShow.cccTspBadges && this.cccTspBadgeWithinWidth
    },
    // 仅romwe
    showStockLeft() {
      return this.labelsShow.stockLeft && this.stockLeftWithinWidth
    },
    // 用户行为提示
    userActTip() {
      if (!this.curData.tspLabels) return ''
      const {
        c7d_click_pv,
        c7d_wishlist_uv,
        c7d_sale_cnt,
        eval_cnt
      } = this.curData.tspLabels
      const {
        SHEIN_KEY_PWA_20804,
        SHEIN_KEY_PWA_20801,
        SHEIN_KEY_PWA_20802,
        SHEIN_KEY_PWA_20803
      } = this.language
      // 用户标签的展示优先级
      // eval_cnt > c7d_click_pv > c7d_wishlist_uv > c7d_sale_cnt

      // 历史评论数>=10k可展示标签，n向下取整
      if (eval_cnt && Math.floor(eval_cnt / 1000) >= 10) {
        return this.handlerUserActText(
          Math.floor(eval_cnt / 1000),
          SHEIN_KEY_PWA_20804,
          `eval_cnt_${Math.floor(eval_cnt / 1000)}k`
        )
      }

      // 7天浏览>=100k可展示标签，n向下取整
      if (c7d_click_pv && Math.floor(c7d_click_pv / 1000) >= 100) {
        return this.handlerUserActText(
          Math.floor(c7d_click_pv / 1000),
          SHEIN_KEY_PWA_20801,
          `c7d_click_pv_${Math.floor(c7d_click_pv / 1000)}k`
        )
      }

      // 7天收藏>=2k可展示标签，n向下取整
      if (c7d_wishlist_uv && Math.floor(c7d_wishlist_uv / 1000) >= 2) {
        return this.handlerUserActText(
          Math.floor(c7d_wishlist_uv / 1000),
          SHEIN_KEY_PWA_20802,
          `c7d_wishlist_uv_${Math.floor(c7d_wishlist_uv / 1000)}k`
        )
      }

      // 7天购买>=1k可展示标签，n向下取整
      if (c7d_sale_cnt && Math.floor(c7d_sale_cnt / 1000) >= 1) {
        return this.handlerUserActText(
          Math.floor(c7d_sale_cnt / 1000),
          SHEIN_KEY_PWA_20803,
          `c7d_sale_cnt_${Math.floor(c7d_sale_cnt / 1000)}k`
        )
      }

      return ''
    },
    goodsBottomAllBadgesReady() {
      if (!this.config.configReady || this.hideLabels) return

      const {
        localMallReady,
        priceCutReady,
        promotionReady,
        badgesReady,
        sellingPointsReady,
        stockLeftReady
      } = this.pretreatInfo || {}
      const {
        showPromotion,
        showBadge,
        showLocalSeller,
        showPriceCut,
        showLeftStock
      } = this.config
      const localSellerStatus = showLocalSeller ? localMallReady : true
      const priceCutStatus = showPriceCut ? priceCutReady : true
      const promotionStatus = showPromotion ? promotionReady : true
      const sellingPointStatus = this.showSellingPointsOnLabel
        ? sellingPointsReady
        : true
      const cccTspBadgeStatus = showBadge ? badgesReady : true
      const leftStockStatus = showLeftStock ? stockLeftReady : true

      return (
        localSellerStatus &&
        priceCutStatus &&
        promotionStatus &&
        sellingPointStatus &&
        cccTspBadgeStatus &&
        leftStockStatus
      )
    },
    isTwoLine() {
      return this.showTwoLine && !this.showOneLine
    }
  },
  watch: {
    goodsBottomAllBadgesReady: {
      async handler(val) {
        this.showSellingPointQuantity = this.showGreySellingPoint ? 1 : this.showSellingPointQuantity
        val && this.handleShouldShowBadge({ bottomLabel: true })
      },
      immediate: true
    },
    finishBottomElCalcWidth(val) {
      if (val) {
        const sellingPoints = []
        if (this.showSellingPointsOnLabel) {
          Object.keys(this.finalSellingPointIndex).forEach(idx => {
            sellingPoints.push(this.sellingPoints[idx])
          })
          if (sellingPoints.length) this.handleShowSellingPoint(sellingPoints)
        }
        this.handleShowCccTspBadge()
        this.showQuickShip && this.handleShowQuickShip()
      }
    }
  },
  methods: {
    async handleShouldShowBadge({ bottomLabel }) {
      await this.$nextTick()

      let bottomBadgeWidth = {}

      if (bottomLabel) {
        this.showSellingPointsOnLabel && this.setFinalSellingPointsIndex() // 计算前，先让所有的卖点都展示
        await this.$nextTick()
        bottomBadgeWidth = this.getBottomBadgeWidth()
      }

      this.bottomFullWidth = this.getWidth(this.$refs.goodsBottomEl) // 总宽

      let row = 1
      const marginL = 8
      const keys = Object.keys(bottomBadgeWidth)

      keys.reduce((acc, cur, index) => {
        const currWidth = bottomBadgeWidth[cur] + (index !== 0 ? marginL : 0)
        this.badgeW = acc + currWidth
        if (this.badgeW <= this.bottomFullWidth) {
          this[`${cur}WithinWidth`] = true
        } else {
          // 第二行
          if (row == 2) {
            if (cur.includes('sellingPoint')) {
              return this.$delete(
                this.finalSellingPointIndex,
                cur[cur.length - 1]
              ) // 删掉放不下的卖点
            } else {
              return (this[`${cur}WithinWidth`] = false)
            }
          }

          // 展示2行
          if (!this.showOneLine) {
            this.badgeW = currWidth
            this.showTwoLine = true
            row += 1
          }

          if (!this.hasEnoughWidth(cur, this.showTwoLine, bottomBadgeWidth)) {
            if (row == 2) row = 1 // 重置
            if (cur.includes('sellingPoint')) {
              this.$delete(this.finalSellingPointIndex, cur[cur.length - 1]) // 删掉放不下的卖点
            } else {
              this[`${cur}WithinWidth`] = false
            }
            this.badgeW = acc // 放不下就取上一次累加的宽度
          }
        }

        return this.badgeW
      }, 0)

      if (bottomLabel) {
        this.finishBottomElCalcWidth = true
      }
    },
    setFinalSellingPointsIndex() {
      for (let i = 0; i < this.showSellingPointQuantity; i++) {
        if (this.sellingPoints[i])
          this.$set(this.finalSellingPointIndex, i, true)
      }
    },
    // 所有角标的宽度
    getBottomBadgeWidth() {
      const bottomBadgeWidth = {}
      const {
        localSellerEl,
        priceCutEl,
        promotionEl,
        cccTspBadgeEl,
        stockLeftEl
      } = this.$refs
      const localSellerWidth = this.getWidth(localSellerEl)
      const priceCutWidth = this.getWidth(priceCutEl)
      const promotionWidth = this.getWidth(promotionEl)
      const cccTspBadgeWidth = this.getWidth(cccTspBadgeEl)
      const stockLeftWidth = this.getWidth(stockLeftEl)
      // 本地商家
      if (localSellerWidth)
        Object.assign(bottomBadgeWidth, { localSeller: localSellerWidth })
      // 减价(price cut)
      if (priceCutWidth)
        Object.assign(bottomBadgeWidth, { priceCut: priceCutWidth })
      // 促销
      if (promotionWidth)
        Object.assign(bottomBadgeWidth, { promotion: promotionWidth })
      // 卖点
      this.assginElWidth({
        len: this.showSellingPointQuantity,
        prefix: 'sellingPoint',
        widthMap: bottomBadgeWidth
      })
      // ccc-tsp角标
      if (cccTspBadgeWidth)
        Object.assign(bottomBadgeWidth, { cccTspBadge: cccTspBadgeWidth })
      // 剩余库存
      if (stockLeftWidth)
        Object.assign(bottomBadgeWidth, { stockLeft: stockLeftWidth })

      return bottomBadgeWidth
    },
    assginElWidth({ len, prefix, widthMap }) {
      for (let i = 0; i < len; i++) {
        const el = this.$refs[prefix + 'El' + i]?.[0]
        const elWidth = this.getWidth(el)
        if (elWidth) Object.assign(widthMap, { [prefix + i]: elWidth })
      }
    },
    // 当前角标能否放得下
    hasEnoughWidth(cur, isMultiRow, bottomBadgeWidth) {
      const isWider = bottomBadgeWidth[cur] > this.bottomFullWidth
      if (!isMultiRow) {
        return this.handleSpecialWidth(cur, bottomBadgeWidth)
      } else {
        if (isWider) {
          return this.handleSpecialWidth(cur, bottomBadgeWidth)
        }

        this[`${cur}WithinWidth`] = true
        return true
      }
    },
    // 处理促销，本地卖家较特殊的宽度
    handleSpecialWidth(cur, bottomBadgeWidth) {
      // 促销和本地卖家角标，展示不下'...'省略
      if (cur == 'promotion' || cur == 'localSeller') {
        const refName = `${cur.replace(cur[0], cur[0].toLowerCase())}El`
        const leftMaxW =
          bottomBadgeWidth[cur] - (this.badgeW - this.bottomFullWidth)

        this.$refs[refName].style.width = `${leftMaxW}px`
        this[`${cur}WithinWidth`] = true

        return true
      }

      return false
    },
    getWidth(el, isCompInstance) {
      const { container = '', safeWidth = '' } = this.calParams
      if (container) {
        const El = isCompInstance ? el?.$el : el
        const containerRight = document
          .getElementsByClassName(container)[0]
          .getBoundingClientRect?.()?.right || 0
        const { right: elRight, left: elLeft } = El?.getBoundingClientRect?.() || {}
        if (elRight > containerRight) {
          const finalWidth = containerRight - elLeft
          if (finalWidth <= safeWidth) {
            El.style.maxWidth = '0px'
          } else {
            El.style.maxWidth = `${finalWidth}px`
            El.style.overflow = 'hidden'
            El.style.textOverflow = 'ellipsis'
          }
        }
      }

      return isCompInstance ? el?.$el?.offsetWidth || 0 : el?.offsetWidth || 0
    },
    handleShowSellingPoint(sellingPoints) {
      const params = []
      sellingPoints.forEach(item => {
        params.push(
          [
            'show_sellingpoint',
            item.tag_id,
            item.tag_val_id,
            item.tag_val_name_en
          ].join('_')
        )
      })

      this.$emit('exposeSellingPoint', params.length ? params.join('|') : '')
    },
    handleShowCccTspBadge() {
      this.$emit('showCccTspBadge', this.showCccTspBadge)
    },
    handleShowQuickShip() {
      this.$emit('showQuickShip')
    },
    handlerUserActText(n, text, showUserActLabel) {
      this.$emit('showUserAct', showUserActLabel)
      return template(n + 'k+', text?.replace('num', '0'))
    },
    handleShowUserActEmit() {
      this.$emit('showUserAct', this.showUserActLabel)
    }
  }
}
</script>

<style lang="less" scoped>
.mini-tags {
  &__reduce-price {
    .text-overflow();
    .font-dpr(24px);
    line-height: 1.2;
    color: @sui_color_highlight;
    margin-top: 0.0533rem;
  }
  &__sale-attr {
    .font-dpr(24px);
    line-height: 1.2;
    color: @sui_color_gray_dark3;
    margin-top: 0.0533rem;
  }
  &__label {
    .flexbox();
    font-size: 0;
    margin-top: 0.1067rem;
    > div:not(:last-of-type) {
      margin-right: 8px;
    }
    &_common {
      .font-dpr(24px);
      height: 0.4267rem;
      line-height: 0.4267rem;
      white-space: nowrap;
      padding: 0 0.1067rem;
      margin-top: 0.1067rem;
    }
    &_small-margin {
      margin-top: 0.1067rem;
    }
    &_two {
      flex-wrap: wrap;
      max-height: 1.0667rem;
      overflow: hidden;
    }
    &_localseller,
    &_quickship {
      .text-overflow();
      flex-shrink: 0;
      max-width: 100%;
      font-size: 12px;
      color: @sui_color_safety;
      background: @sui_color_safety_bg;
    }
    &_pricecut {
      color: @sui_color_highlight;
    }
    &_promotion {
      .text-overflow();
      flex-shrink: 0;
      max-width: 100%;
      background: @sui_color_promo_bg;
      color: @sui_color_promo_dark;
    }
    &_sellingpoint {
      color: @sui_color_micro_emphasis;
      background-color: @sui_color_micro_emphasis_bg;
    }
    &_stockleft {
      color: @sui_color_highlight;
    }
  }
  &__userAct-label {
    .text-overflow();
    color: @sui_color_micro_emphasis;
    background: #fff;
    padding-left: 0;
    margin-top: 0.1067rem;
  }
  &__search-filter {
    .flexbox();
    .search-filter {
      &__label {
        .flexbox();
        .align-center();
        max-width: 100%;
        height: 16px;
        line-height: 16px;
        padding: 0 0.1067rem;
        margin-top: 0.1067rem;
        color: @sui_color_gray_dark2;
        background: @sui_color_gray_weak2;
        &:not(:last-of-type) {
          margin-right: 8px;
        }
        /* stylelint-disable-next-line selector-max-specificity */
        .suiiconfont.sui_icon_search_16px {
          display: inline-block;
          height: 12px;
          line-height: 12px;
          font-size: 12px;
          margin-left: 0.0533rem;
        }
      }
      &__label-text {
        .text-overflow();
        // 4.52rem -> 2图宽度，12px -> 图标宽度，0.2134rem -> 左右padding，0.0533rem -> 图标左边距
        max-width: calc(4.52rem - 12px - 0.2134rem - 0.0533rem);
      }
    }
  }
}
</style>
